<script>
import GenericChart from './components/generic.vue'
export default {
  components: { GenericChart },
  props: {
    sensor: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      customChart: this.sensor,
      data: null
    }
  },
  methods: {
    async fetchData() {
      try {
        if (this.customChart.tyde_id) {
          const response = await this.axios.get(
            `/om_dashboard/sensor_data?sensor_name=${this.customChart.name}&start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
          )
          this.data = response.data
        } else {
          console.warn(`${this.customChart.description} sensor is not available`)
        }
      } catch (error) {
        console.error(`Error fetching ${this.customChart.description} data:`, error)
      }
    }
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>

<template lang="pug">
div
  .heading
    span.header-text {{ customChart.description }}
    span.grey--text.pr-4 ({{ drilldownFacade?.chart_range_description }})

  v-row(no-gutters)
    v-col(cols="10").pr-16
      GenericChart(v-if="data" :data="data" :name="customChart.description" :unit="customChart.unit")
</template>
