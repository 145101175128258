<script>
import ChartsOmDashboardVolume from "./components/volume.vue"

export default {
  name: 'Effect',
  components: {ChartsOmDashboardVolume},
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  methods: {
    omNum(value) {
      return value.toFixed(3)
    }
  }
}
</script>

<template lang="pug">
div
  .heading
    span.header-text {{ $t('general.total_production') }}
    span.grey--text.pr-4 ({{ drilldownFacade.chart_range_description }})
    v-chip(:label="true" :class="powerPlant.prod_status?.table?.chip_color")
      | {{ powerPlant.current_effect }} / {{ powerPlant.rated_power_mw }} MW
  v-row(no-gutters)
    v-col(cols="10").pr-16
      ChartsOmDashboardVolume(
        :data="drilldownFacade.hourly_volume"
        :rated_power_mw="powerPlant.rated_power_mw"
        :series_color="powerPlant.prod_status?.table?.chart_colors"
      )
    v-col(cols="2")
      .details
        div
          div {{ $t('om_dashboard.drilldown.frequency') }}
          div
            div(v-if="drilldownFacade.frequency_sensor")
              | {{ omNum(drilldownFacade.frequency_sensor.latest_value) }} {{ drilldownFacade.frequency_sensor.unit || 'Hz' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'frequency' }) }}
        div
          div {{ $t('om_dashboard.drilldown.active_power') }}
          div
            div(v-if="drilldownFacade.turbine_1_active_power_kw_sensor")
              | {{ omNum(powerPlant.current_effect) }} {{ drilldownFacade.turbine_1_active_power_kw_sensor.unit || 'kW' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'generator_effect' }) }}
        div
          div {{ $t('om_dashboard.drilldown.reactive_power') }}
          div
            div(v-if="drilldownFacade.reactive_effect_sensor")
              | {{ omNum(drilldownFacade.reactive_effect_sensor.latest_value) }} {{ drilldownFacade.reactive_effect_sensor.unit || 'kVa' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'reactive_effect' }) }}
        div
          div {{ $t('om_dashboard.drilldown.supervision_power') }}
          div
            div(v-if="drilldownFacade.apparent_effect_sensor")
              | {{ omNum(drilldownFacade.apparent_effect_sensor.latest_value) }} {{ drilldownFacade.apparent_effect_sensor.unit || 'kVa' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'apparent_effect' }) }}
        div
          div {{ $t('om_dashboard.drilldown.gen_l1l2') }}
          div
            div(v-if="drilldownFacade.gen_l1_l2_sensor")
              | {{ omNum(drilldownFacade.gen_l1_l2_sensor.latest_value) }} {{ drilldownFacade.gen_l1_l2_sensor.unit || 'V' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'gen_l1_l2' }) }}
        div
          div {{ $t('om_dashboard.drilldown.gen_l2l3') }}
          div
            div(v-if="drilldownFacade.gen_l2_l3_sensor")
              | {{ omNum(drilldownFacade.gen_l2_l3_sensor.latest_value) }} {{ drilldownFacade.gen_l2_l3_sensor.unit || 'V' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'gen_l2_l3' }) }}
        div
          div {{ $t('om_dashboard.drilldown.gen_l3l1') }}
          div
            div(v-if="drilldownFacade.gen_l3_l1_sensor")
              | {{ omNum(drilldownFacade.gen_l3_l1_sensor.latest_value) }} {{ drilldownFacade.gen_l3_l1_sensor.unit || 'V' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'gen_l3_l1' }) }}
</template>
