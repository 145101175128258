<script>
import ChartsOmDashboardWater from "./components/water.vue"

export default {
  name: 'WaterLevel',
  components: { ChartsOmDashboardWater },
  props: {
    powerPlant: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hourlyWaterLevelData: null
    }
  },
  computed: {
    waterLevelUpstreamSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.water_level_upstream
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    tempOutsideSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.temp_outside
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    setPointWaterLevelSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.set_point_water_level
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    autostartSpSensor() {
      const sensorId = this.drilldownFacade?.sensor_mapping?.autostart_sp
      return sensorId ? this.drilldownFacade.sensors.find(sensor => sensor.tyde_id === sensorId) : null
    },
    customDescription() {
      return this.waterLevelUpstreamSensor?.description ?? this.$t('om_dashboard.drilldown.water_level')
    }
  },
  methods: {
    omNum(value) {
      return value ? value.toFixed(3) : '0.00'
    },
    async fetchHourlyWaterLevel() {
      try {
        if (this.waterLevelUpstreamSensor) {
          const response = await this.axios.get(`/om_dashboard/sensor_data/?sensor_name=${this.waterLevelUpstreamSensor.name}&start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`)
          this.hourlyWaterLevelData = response.data

          if (this.hourlyWaterLevelData && this.hourlyWaterLevelData.length > 0) {
            this.waterLevelUpstreamSensor.latest_value = this.hourlyWaterLevelData[this.hourlyWaterLevelData.length - 1][1]
          } else {
            console.warn('Time series data is empty')
          }
        } else {
          console.warn('Water level upstream sensor is not available')
        }
      } catch (error) {
        console.error('Error fetching hourly water level data:', error)
      }
    }
  },
  async mounted() {
    await this.fetchHourlyWaterLevel()
  }
}
</script>

<template lang="pug">
div
  .heading
    span.header-text {{ customDescription }}
    span.grey--text.pr-4 ({{ drilldownFacade?.chart_range_description }})
    v-chip(v-if="waterLevelUpstreamSensor" :label="true")
      | {{ waterLevelUpstreamSensor.latest_value?.toFixed(3) }} moh
  v-row(no-gutters)
    v-col(cols="10").pr-16
      ChartsOmDashboardWater(
        v-if="waterLevelUpstreamSensor"
        :data="hourlyWaterLevelData"
        name="Water level"
        unit="moh"
        :upper_threshold="drilldownFacade?.intakes?.upper_alert_limit"
        :lower_threshold="drilldownFacade?.intakes?.lower_alert_limit"
      )
      div(v-else class="mt-2")
        span.grey--text {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'water_level' }) }}
    v-col(cols="2")
      .details
        div
          div {{ $t('om_dashboard.drilldown.water_level') }}
          div(v-if="waterLevelUpstreamSensor")
            | {{ waterLevelUpstreamSensor.latest_value?.toFixed(3) }} moh
          div(v-else)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
              span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'water_level' }) }}
        div
          div {{ $t('om_dashboard.drilldown.temp_outside') }}
          div
            div(v-if="tempOutsideSensor")
              | {{ omNum(tempOutsideSensor.latest_value) }} {{ tempOutsideSensor.unit || '°C' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'temp_outside' }) }}
        div
          div {{ $t('om_dashboard.drilldown.set_point_water_level') }}
          div
            div(v-if="setPointWaterLevelSensor")
              | {{ omNum(setPointWaterLevelSensor.latest_value) }} {{ setPointWaterLevelSensor.unit || 'm' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'set_point_water_level' }) }}
        div
          div {{ $t('om_dashboard.drilldown.set_point_autostart') }}
          div
            div(v-if="autostartSpSensor")
              | {{ omNum(autostartSpSensor.latest_value) }} {{ autostartSpSensor.unit || 'm' }}
            div(v-else)
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
                span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'autostart_sp' }) }}
</template>
