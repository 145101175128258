<script>
import VDatePickerInput from '../../components/v_date_picker_input_enhanced.vue'
import AccountForm from './accounts_form.vue'
import AccountFormField from './account_form_field.vue'
import ValueMixin from './value_mixins.vue'
import ContractChipRow from './contract_chip_row.vue'
import SummaryTable from './summary_table.vue'
import { DateTime } from 'luxon'
// Import action cable channel fall_lease
import { createConsumer } from '@rails/actioncable'

export default {
  components: {
    VDatePickerInput,
    AccountForm,
    AccountFormField,
    ContractChipRow,
    SummaryTable
  },
  props: {
    fall_lease_contract: Object,
    contract: Object,
    model_types: Array,
    threshold_types: Array,
    threshold_methods: Array,
    kpi_adjustments: Array,
    averaging_intervals: Array,
    payment_intervals: Array,
    power_plants: Array,
    power_plant_fall_leases: Array,
    xledger_project_id: [Number, null],
    landowners: Array,
    admin_user: Boolean
  },
  mixins: [ValueMixin],
  mounted() {
    this.consumer = createConsumer()

    this.local_power_plant_fall_leases.forEach((contract) => {
      this.openChannel(contract.fall_lease)
    })
  },
  data() {
    return {
      consumer: null,
      connectedChannels: {},
      showAccountForm: false,
      currentAccountType: null,
      currentCodes: null,
      form_patch_request_headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },

      sorted_intervals: ['hourly', 'daily', 'monthly', 'quarterly', 'half-yearly', 'yearly'],
      local_contract: { ...this.contract },
      local_fall_lease_contract: { ...this.fall_lease_contract },
      local_power_plant_fall_leases: [...this.power_plant_fall_leases],

      ACCOUNT_TYPES: ['revenue', 'elcert', 'goos', 'total_costs'],

      currencies: ['NOK', 'EUR'],
      form_fall_lease_button_color: 'primary',
      form_fall_lease_loading: false,
      valid: true,
      fall_lease_value: null,

      remove_all_loading: false
    }
  },
  computed: {
    filtered_payment_intervals() {
      let selected_averaging_interval = this.local_fall_lease_contract.averaging_interval
      let index = this.sorted_intervals.indexOf(selected_averaging_interval)
      let filtered_intervals = this.sorted_intervals.slice(index)
      let si = this.sorted_intervals
      return this.payment_intervals
        .filter((item) => filtered_intervals.includes(item.value))
        .sort(function (a, b) {
          return si.indexOf(a.value) - si.indexOf(b.value)
        })
    },
    sorted_averaging_intervals() {
      let si = this.sorted_intervals
      return this.averaging_intervals.sort(function (a, b) {
        return si.indexOf(a.value) - si.indexOf(b.value)
      })
    },
    show_loading_spinners() {
      return this.local_fall_lease_contract.computing || this.form_fall_lease_loading
    },
    input_fields_disabled() {
      return (
        this.form_fall_lease_loading ||
        this.local_fall_lease_contract.approved ||
        this.local_fall_lease_contract.locked ||
        this.local_fall_lease_contract.computing
      )
    },
    any_channel_disconnected() {
      let disconnected = Object.values(this.connectedChannels).find((connected) => !connected)
      return disconnected !== undefined
    },
    computing_job_status_color() {
      const statusColorMap = {
        not_found: 'red',
        in_queue: 'yellow',
        running: 'green'
      }

      return statusColorMap[this.local_fall_lease_contract.computing_job_status]
    },
    kpi_end_date_types() {
      return [
        { text: 'End of Last Year', value: 'end_of_last_year' },
        { text: 'Start of Year', value: 'start_of_year' },
        { text: 'Mid-Year', value: 'mid_year' },
        { text: 'End of Year', value: 'end_of_year' }
      ]
    },
    fallLeaseLandowners() {
      if (this.contract.fall_lease_landowners === undefined) return ''

      return this.contract.fall_lease_landowners
        .filter((l) => !l._destroy)
        .map((l) => ({
          text:
            (this.landowners.find((u) => u.value === l.user_id)?.text || 'User not found..') +
            `(${l.share}%)`,
          value: l.user_id
        }))
    }
  },
  methods: {
    getYear(date) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').year
    },
    openChannel(fall_lease) {
      this.consumer.subscriptions.create(
        { channel: 'FallLeaseChannel', id: fall_lease.id },
        {
          connected: () => {
            this.$set(this.connectedChannels, fall_lease.id, true)
          },
          disconnected: () => {
            this.$set(this.connectedChannels, fall_lease.id, false)
          },
          received: (fall_lease) => {
            if (!fall_lease) return

            const index = this.local_power_plant_fall_leases.findIndex(
              (contract) => contract.fall_lease.id === fall_lease.id
            )
            if (index >= 0) {
              this.local_power_plant_fall_leases[index].fall_lease = fall_lease
              this.$set(this.local_power_plant_fall_leases, index, this.local_power_plant_fall_leases[index])
            }

            if (this.local_fall_lease_contract.id === fall_lease.id)
              this.local_fall_lease_contract = fall_lease
          }
        }
      )
    },
    toggleAccountForm(accountType, data = null) {
      if (this.local_fall_lease_contract.approved || this.local_fall_lease_contract.locked) {
        return
      }

      this.currentAccountType = accountType

      if (data) {
        this.local_fall_lease_contract[accountType] = data.inputValue
        this.local_fall_lease_contract[`${accountType}_codes`] = data.dataCodes
        this.currentCodes = data.dataCodes
      }

      this.showAccountForm = true
    },
    keep_attributes(object, attributes) {
      const result = {}
      for (const attr of attributes) {
        if (object.hasOwnProperty(attr)) {
          result[attr] = object[attr]
        }
      }
      return result
    },
    async compute_all_fall_lease(event) {
      if (confirm(this.$t('fall_lease.compute_all_prompt'))) {
        let included_attributes = [
          'model_type',
          'model_rate',
          'averaging_interval',
          'threshold_type',
          'threshold_method',
          'threshold_value',
          'threshold_rate',
          'min_price',
          'fixed_price',
          'kpi_adjustment',
          'kpi_ref_date',
          'kpi_end_date_type',
          'payment_dates',
          'payment_interval',
          'currency',
          'elcert_share',
          'goos_share',
          'revenue_codes',
          'elcert_codes',
          'goos_codes',
          'total_costs_codes',
          'revenue_owners',
          'elcert_owners',
          'goos_owners',
          'total_costs_owners'
        ]

        const filtered_fall_lease_attributes = this.keep_attributes(
          this.local_fall_lease_contract,
          included_attributes
        )

        await this.axios.patch(
          `/admin/fall_leases/${this.local_fall_lease_contract.id}`,
          filtered_fall_lease_attributes
        )

        await this.axios.post(`/admin/fall_leases/${this.local_contract.id}/compute_all`, {
          headers: this.form_patch_request_headers
        })
      }
    },
    async submit_fall_lease() {
      try {
        this.form_fall_lease_loading = true
        this.local_contract.fall_lease_attributes = this.local_contract.fall_lease
        const headersConfig = this.form_patch_request_headers

        await this.axios.patch(`/admin/contracts/${this.contract.id}.json`, {
          contract: this.local_contract,
          headers: headersConfig
        })

        await this.axios.patch(`/admin/fall_leases/${this.fall_lease_contract.id}.json`, {
          fall_lease: this.local_fall_lease_contract,
          headers: headersConfig
        })

        await this.axios.post(`/admin/fall_leases/${this.fall_lease_contract.id}/compute`, {
          headers: headersConfig
        })

        this.form_fall_lease_button_color = 'primary'
        this.form_fall_lease_loading = false
      } catch (error) {
        console.error(error)
      }
    },
    async download_fall_lease_xlsx() {
      const fall_lease_url = `/admin/fall_leases/${this.local_contract.id}.xlsx`
      await this.axios
        .get(fall_lease_url, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => console.error(error))
    },
    disapprove_fall_lease() {
      this.axios.post(`/admin/fall_leases/${this.fall_lease_contract.id}/disapprove`)
    },
    approve_fall_lease() {
      this.axios.post(`/admin/fall_leases/${this.fall_lease_contract.id}/approve`)
    },
    fix_computing_job() {
      if (this.local_fall_lease_contract.computing_job_status !== 'not_found') return
      this.axios.post(`/admin/fall_leases/${this.fall_lease_contract.id}/fix_computing_job`)
    },
    async toggle_fall_lease_lock(allContracts = false) {
      this.form_fall_lease_loading = true

      const ids = allContracts
        ? this.local_power_plant_fall_leases.map((contract) => contract.fall_lease.id)
        : [this.local_fall_lease_contract.id]

      try {
        await this.axios.patch(`/admin/fall_leases/set_lock`, { ids_to_lock: ids })

        this.form_fall_lease_loading = false
      } catch (exception) {
        console.error('Error in toggling lock: ', exception)
        return
      }
    },
    download_fall_lease(event) {
      this.download_fall_lease_xlsx(event)
    },
    isXledgerProjectIdPresent() {
      return this.xledger_project_id != null
    },
    is_contract_in_future() {
      return DateTime.now() < DateTime.fromFormat(this.local_contract.start_date, 'yyyy-MM-dd')
    },
    async remove_all() {
      if (confirm(this.$t('fall_lease.remove_all_prompt'))) {
        this.remove_all_loading = true
        await Promise.all(
          this.local_power_plant_fall_leases.map((contract) =>
            this.axios.delete(`/admin/contracts/${contract.id}`)
          )
        )
        this.remove_all_loading = false
        this.window.location = '/admin/fall_leases/'
      }
    }
  },
  watch: {
    'local_fall_lease_contract.model_rate'(newVal) {
      if (typeof newVal === 'number') {
        newVal = newVal.toString()
      }
      if (typeof newVal === 'string') {
        this.local_fall_lease_contract.model_rate = newVal.replace(/,/g, '.')
      }
    }
  }
}
</script>

<template lang="pug">
  div
    AccountForm(
      v-model="showAccountForm"
      :fall_lease_contract="local_fall_lease_contract"
      :contract="local_contract"
      :selected_account_type="currentAccountType"
      :selected_codes="currentCodes"
    )
    v-container#contract_details
      v-card
        v-card-title.headline.grey.lighten-2 
          div.pr-2 
            | {{ this.$t('general.contract_details') }}
          v-spacer
          div(v-if="local_fall_lease_contract.computing_job_status !== 'not_computing'")
            v-chip(
              :color="computing_job_status_color" 
              @click="fix_computing_job" 
              small
            ) {{ this.$t('fall_lease.computing_status') + ": " + this.$t(`fall_lease.computing_job_status.${local_fall_lease_contract.computing_job_status}`) }}
          div(v-if="any_channel_disconnected")
            v-chip(color="red" small) {{ this.$t('fall_lease.websocket_disconnected') }}
        v-card-text
          v-row.py-3
            ContractChipRow(:current_contract="local_contract" :fall_leases="local_power_plant_fall_leases", :admin_user="admin_user")

          v-row
            v-col
              v-autocomplete(
                v-model="local_contract.power_plant_id"
                :label="$t('general.power_plant')"
                :disabled="input_fields_disabled"
                :items="power_plants"
                filled
                disabled
              )
            v-col
              v-select.ignore-readonly-styles(
                @click.prevent="counterpartDialog = true"
                :value="fallLeaseLandowners"
                :items="fallLeaseLandowners"
                :label="$t('fall_lease.counterpart')"
                filled chips readonly multiple disabled
              )
            v-col
              VDatePickerInput(
                v-model="local_contract.start_date"
                :label="$t('fall_lease.start_date')"
                :disabled="input_fields_disabled"
                filled
              )
            v-col
              VDatePickerInput(
                v-model="local_contract.end_date"
                :label="$t('fall_lease.end_date')"
                :disabled="input_fields_disabled"
                filled
              )
            v-col(lg="2")
              v-select(
                v-model="local_fall_lease_contract.currency"
                :label="$t('fall_lease.currency')"
                :disabled="input_fields_disabled"
                :items="currencies"
                filled
              )
            v-col
              v-checkbox.pt-9(
                v-model="local_contract.active"
                :label="$t('fall_lease.active')"
                :disabled="local_fall_lease_contract.approved"
                filled
              )

    v-container#fall_lease_contract_details(
      v-show="local_contract.type === 'Contract::FallLease'"
    )
      v-card
        v-card-title.headline.grey.lighten-2
          | {{ this.$t('fall_lease.fall_lease_contract_details') }}
        v-card-text
          v-form(
            ref="form"
            v-model="valid"
            lazy-validation
          )
            v-row
              v-col
                v-subheader {{ this.$t('fall_lease.contract_structure') }}
                v-select(
                  v-model="local_fall_lease_contract.model_type"
                  :label="$t('fall_lease.model_type')"
                  :disabled="input_fields_disabled"
                  :items="model_types"
                  filled
                )
                v-select(
                  v-model="local_fall_lease_contract.threshold_type"
                  :label="$t('fall_lease.threshold_type')"
                  :disabled="input_fields_disabled"
                  :items="threshold_types"
                  filled
                )
                v-select(
                  v-model="local_fall_lease_contract.averaging_interval"
                  :label="$t('fall_lease.calculation_interval')"
                  :disabled="input_fields_disabled"
                  :items="sorted_averaging_intervals"
                  filled
                )
                v-select(
                  v-model="local_fall_lease_contract.payment_interval"
                  :label="$t('fall_lease.payment_interval')"
                  :disabled="input_fields_disabled"
                  :items="filtered_payment_intervals"
                  filled
                  disabled
                )
              v-col
                v-subheader {{ this.$t('fall_lease.rate_structure') }}
                v-select(
                  v-model="local_fall_lease_contract.threshold_method"
                  :label="$t('fall_lease.threshold_method')"
                  :disabled="local_fall_lease_contract.threshold_type === 'none' || input_fields_disabled"
                  :items="threshold_methods"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.threshold_value"
                  :label="$t('fall_lease.threshold_value')"
                  :disabled="local_fall_lease_contract.threshold_type === 'none' || input_fields_disabled"
                  :hint="$t('fall_lease.threshold_value_hint')"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.threshold_rate"
                  :label="$t('fall_lease.threshold_rate')"
                  :disabled="local_fall_lease_contract.threshold_type === 'none' || input_fields_disabled"
                  :hint="$t('fall_lease.threshold_rate_hint')"
                  suffix="%"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.model_rate"
                  :label="$t('fall_lease.model_rate')"
                  :disabled="input_fields_disabled"
                  v-show="local_fall_lease_contract.model_type !== 'fixed_price' && local_fall_lease_contract.threshold_type === 'none'"
                  :hint="$t('fall_lease.model_rate_hint')"
                  suffix="%"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.elcert_share"
                  :label="$t('fall_lease.elcert_share')"
                  :disabled="input_fields_disabled"
                  suffix="%"
                  filled
                  dense
                )
                v-text-field(
                  v-model="local_fall_lease_contract.goos_share"
                  :label="$t('fall_lease.goos_share')"
                  :disabled="input_fields_disabled"
                  suffix="%"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.min_price"
                  :label="$t('fall_lease.min_price')"
                  :disabled="input_fields_disabled"
                  :suffix="local_fall_lease_contract.currency"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.fixed_price"
                  :label="$t('fall_lease.fixed_price')"
                  :disabled="input_fields_disabled"
                  :suffix="local_fall_lease_contract.currency"
                  filled
                )
              v-col
                v-subheader {{ this.$t('fall_lease.pp_revenues_costs') }}
                div(v-for="account_type in ACCOUNT_TYPES")
                  account-form-field(
                    :id="`field-account-${account_type}`"
                    v-model="local_fall_lease_contract[account_type]",
                    :label="$t(`fall_lease.${account_type}`)",
                    :disabled="input_fields_disabled",
                    :currency="local_fall_lease_contract.currency",
                    :codes="local_fall_lease_contract[`${account_type}_codes`]"
                    :hasProjectId="isXledgerProjectIdPresent()"
                    @click="toggleAccountForm(account_type, $event)"
                  )
              v-col
                v-subheader {{ this.$t('fall_lease.kpi_adjustment') }}
                v-select(
                  v-model="local_fall_lease_contract.kpi_adjustment"
                  :label="$t('fall_lease.kpi_adjustment')"
                  :disabled="input_fields_disabled"
                  :items="kpi_adjustments"
                  filled
                )
                VDatePickerInput(
                  v-model="local_fall_lease_contract.kpi_ref_date"
                  :label="$t('fall_lease.kpi_ref_date')"
                  :disabled="local_fall_lease_contract.kpi_adjustment === 'none' || input_fields_disabled"
                  filled
                )
                v-select(
                  v-model="local_fall_lease_contract.kpi_end_date_type"
                  :items="kpi_end_date_types"
                  :label="$t('fall_lease.kpi_end_date_type')"
                  :disabled="local_fall_lease_contract.kpi_adjustment === 'none' || input_fields_disabled"
                  filled
                )
                v-text-field(
                  v-model="local_fall_lease_contract.payment_dates"
                  :label="$t('fall_lease.payment_dates')"
                  :disabled="input_fields_disabled"
                  filled
                )

        v-card-actions
          v-btn(
            @click.prevent="remove_all"
            color="error"
            :loading="remove_all_loading"
          )
            | {{ this.$t('fall_lease.remove_all') }}
          v-spacer
          v-btn(
            @click.prevent="submit_fall_lease"
            :color="form_fall_lease_button_color"
            :disabled="local_fall_lease_contract.locked && local_fall_lease_contract.computable"
            :loading="show_loading_spinners"
          )
            | {{ this.$t('fall_lease.compute_current_year') }}
          v-btn(
            @click.prevent="compute_all_fall_lease"
            :color="form_fall_lease_button_color"
          )
            | {{ this.$t('fall_lease.save_compute_all') }}
    SummaryTable(:fall_lease_contract="local_fall_lease_contract")
      v-btn(
        :color="form_fall_lease_button_color"
        :href="`/admin/fall_leases?year=${getYear(this.local_contract.start_date)}`"
      ) {{ this.$t('general.back_to_list') }}
      v-btn(
        @click.prevent="download_fall_lease"
        :color="form_fall_lease_button_color"
        :disabled="!local_fall_lease_contract.approved"
        :loading="show_loading_spinners"
      )
        | {{ this.$t('fall_lease.download_xlsx') }}
      v-spacer
      v-btn(
        @click="toggle_fall_lease_lock(true)"
        color="error"
        :loading="show_loading_spinners"
      )
        | {{ this.$t('fall_lease.unlock_all') }}
      v-spacer
      v-btn(
        @click.prevent="toggle_fall_lease_lock(false)"
        v-show="local_fall_lease_contract.locked"
        :loading="show_loading_spinners"
        color="error"
      )
        | {{ this.$t('fall_lease.unlock') }}
      v-btn(
        @click.prevent="disapprove_fall_lease"
        :color="form_fall_lease_button_color"
        :disabled="is_contract_in_future()"
        v-show="local_fall_lease_contract.approved"
        :loading="show_loading_spinners"
        color="warning"
      )
        | {{ this.$t('fall_lease.disapprove') }}
      v-btn(
        @click.prevent="approve_fall_lease"
        :color="form_fall_lease_button_color"
        :disabled="local_fall_lease_contract.approved || is_contract_in_future()"
        :loading="show_loading_spinners"
      )
        span(v-if="!local_fall_lease_contract.approved") {{ this.$t('fall_lease.approve') }}
        span(v-else) {{ this.$t('fall_lease.confirmed') }}
</template>
